import React from 'react';

import WelcomePage from '../Components/WelcomePage';

import "./HomePage.css"

const HomePage = () => {


    return(
        <React.Fragment>
            <WelcomePage/>
        </React.Fragment>
    )
}

export default HomePage